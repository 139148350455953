import React from "react";
import DestinationsBoxGrid from "./destinations-box-grid";
import DualCTA from "../misc/dual-cta";

const DestinationsBoxSection = ({ heading, paragraph, integrationUrl = null, note = null }) => {
    const Note = () => {
        return (
            <div className="note">
                <p>
                    <strong>Need a warehouse?</strong> Not a problem. You can provision one in seconds inside of Stitch.
                </p>
                <div className="st-callout signup-form">
                    <DualCTA description={false} />
                </div>
            </div>
        );
    };

    return (
        <>
            <section className="st-section--border warehouses" id="warehouses">
                <div className="st-feature content">
                    <h2 className="st-heading--2">{heading}</h2>
                    <p className="st-subheading">{paragraph}</p>
                    <DestinationsBoxGrid integrationUrl={integrationUrl} />
                    {note && <Note />}
                </div>
            </section>
        </>
    );
};

export default DestinationsBoxSection;
