import React from "react";
import Link from "../misc/link";
import Icon1 from "../../assets/images/icons/icon-integrations-simple.svg";
import Icon2 from "../../assets/images/icons/icon-integrations-infrastructure.svg";
import Icon3 from "../../assets/images/icons/icon-features-replication.svg";

const WhyChooseStitch = () => {
    return (
        <section className="st-section">
            <div className="st-callout features">
                <div className="callout-content">
                    <h2 className="st-heading--2">Why our customers choose Stitch</h2>
                    <p className="st-subheading">
                        Stitch is a simple, powerful ETL service built for developers. Stitch connects to your
                        first-party data sources – from databases like MongoDB and MySQL, to SaaS tools like Salesforce
                        and Zendesk – and replicates that data to your warehouse. With Stitch, developers can provision
                        data for their internal users in minutes, not weeks.
                    </p>
                    <Link to={"/platform/extensibility"} className="st-button-cta desktop">
                        Explore all of Stitch's features<span className="arrow"></span>
                    </Link>
                </div>
                <div className="callout-detail-list">
                    <dl>
                        <dt className="st-heading--4">
                            <span className="st-icon">
                                <img src={Icon1} alt="Icon" />
                            </span>
                            Simple setup
                        </dt>
                        <dd>Start replicating data in minutes, and never worry about ETL maintenance.</dd>

                        <dt className="st-heading--4">
                            <span className="st-icon">
                                <img src={Icon2} alt="Icon" />
                            </span>
                            Own your own data infrastructure
                        </dt>
                        <dd>Stitch replicates to your warehouse, meaning you’re always in control.</dd>

                        <dt className="st-heading--4">
                            <span className="st-icon">
                                <img src={Icon3} alt="Icon" />
                            </span>
                            Mature replication engine
                        </dt>
                        <dd>Accurate data from any structure, all the time.</dd>
                    </dl>
                    <Link className="st-button-cta mobile" to={"/platform/extensibility"}>
                        Explore all of Stitch's features<span className="arrow"></span>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseStitch;
