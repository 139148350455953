import React from "react";
import Link from "../misc/link";

const TestimonialSection = ({
    quote,
    author,
    position,
    companyName = null,
    companyUrl = null,
    logoUrl = null,
    logoAltText = null,
}) => {
    return (
        <section className="st-section">
            <div className="st-testimonial">
                <div className="testimonial-content">
                    <q>{quote}</q>
                </div>
                <div className="author">
                    <h4 className="st-heading--4">{author}</h4>
                    {companyName && companyUrl ? (
                        <p>
                            {position}, <Link to={companyUrl}>{companyName}</Link>
                        </p>
                    ) : (
                        <p>{position}</p>
                    )}
                    {logoUrl && logoAltText && (
                        <p>
                            <img src={logoUrl} alt={logoAltText} height="50" />
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;
