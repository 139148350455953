import React from "react";

const VideoContainer = ({ videoEmbedCode, caption }) => {
    return (
        <section className="st-section">
            <div className="st-stitch-divider">
                <div className="st-video-container" dangerouslySetInnerHTML={{ __html: videoEmbedCode }} />
                <span>
                    <p>{caption}</p>
                </span>
            </div>
        </section>
    );
};

export default VideoContainer;
