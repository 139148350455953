import React from "react";
import Link from "../misc/link";

const AnalysisToolCalloutSection = ({ name, tutorial }) => {
    return (
        <section className="st-section">
            <div className="st-callout">
                <div className="callout-content">
                    <h2 className="st-heading--2">Stitch makes it easy to use {name}</h2>
                    <p className="st-subheading">
                        Stitch structures your data for compatibility with the leading data analysis tools and
                        platforms.
                    </p>
                </div>
                <div className="callout-detail-list">
                    <dl>
                        <dt className="st-heading--4">You choose the destination</dt>
                        <dd>
                            Send your data to the leading data storage platforms and use it to power insights in {name}.
                        </dd>
                        <dt className="st-heading--4">Detailed documentation</dt>
                        <dd>Know exactly what data is coming and how it's structured.</dd>
                        <dt className="st-heading--4">Visibility</dt>
                        <dd>Know when your data is updated with detailed logs and orchestration features.</dd>

                        {tutorial && (
                            <>
                                <dt className="st-heading--4">Connecting to {name} is easy</dt>
                                <dd>
                                    <p>
                                        Follow this step-by-step tutorial for connecting {name} to your Stitch data
                                        warehouse.
                                    </p>
                                    <Link to={tutorial} className={`st-button`}>
                                        See the {name} Tutorial<span className="arrow"></span>
                                    </Link>
                                </dd>
                            </>
                        )}
                    </dl>
                </div>
            </div>
        </section>
    );
};

export default AnalysisToolCalloutSection;
