import React from "react";

const EcoSystemDiagram = ({ logoUrl, logoAltText }) => {
    return (
        <section className="st-section stitch-diagram">
            <div className="st-feature">
                <div className="st-feature content">
                    <div className="diagram one"></div>
                    <div className="diagram two"></div>
                    <div className="diagram three"></div>
                    <div className="diagram four">
                        <div className="logo-block">
                            <div className="logo-content">
                                <img src={logoUrl} alt={logoAltText} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EcoSystemDiagram;
