import { useStaticQuery, graphql } from "gatsby";

export const useVideoSelector = (type = null, matchParams = []) => {
    const data = useStaticQuery(
        graphql`
            query VideosQuery {
                allContentfulDemoVideo {
                    nodes {
                        analyticsTool {
                            slug
                        }
#                        source {
#                            type_id
#                        }
                        destination {
                            type_id
                        }
                        embedCode {
                            embedCode
                        }
                        caption {
                            caption
                        }
                    }
                }
            }
        `
    );

    const allVideos = data.allContentfulDemoVideo.nodes;
    let selectedVideo = null;

    if (matchParams.length && type === "analysis-tool") {
        selectedVideo = allVideos.filter(
            (video) => video.analyticsTool && video.analyticsTool.some((tool) => tool.slug === matchParams[0])
        );
    }

    if (matchParams.length && type === "integration") {
        selectedVideo = allVideos.filter(
            (video) => video.source && video.source.some((source) => source.type_id === matchParams[0])
        );
    }

    if (matchParams.length && type === "destination") {
        selectedVideo = allVideos.filter((video) => video.destination && video.destination.type_id === matchParams[0]);
    }

    if (matchParams.length && type === "integration-destination") {
        selectedVideo = allVideos.filter(
            (video) =>
                video.source &&
                video.destination &&
                video.source.some((source) => source.type_id === matchParams[0]) &&
                video.destination.type_id === matchParams[1]
        );
    }

    if (matchParams.length && type === "integration-integration") {
        selectedVideo = allVideos.filter(
            (video) =>
                video.source &&
                video.source.some((source) => source.type_id === matchParams[0]) &&
                video.source.some((source) => source.type_id === matchParams[1])
        );
    }

    if (matchParams.length && type === "integration-tool") {
        selectedVideo = allVideos.filter(
            (video) =>
                video.source &&
                video.analyticsTool &&
                video.source.some((source) => source.type_id === matchParams[0]) &&
                video.analyticsTool.some((tool) => tool.slug === matchParams[1])
        );
    }

    return selectedVideo.length ? selectedVideo : null;
};
