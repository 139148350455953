import React from "react";
import Link from "../misc/link";

const AboutAnalysisToolSection = ({ name, logoUrl, logoAltText, description, url }) => {
    return (
        <section className="st-section--border tool" id="tool">
            <div className="st-feature">
                <div className="st-feature content">
                    <div className="logo-description logo">
                        <img src={logoUrl} alt={logoAltText} />
                    </div>
                    <div className="logo-description description">
                        <p>{description}</p>
                        <Link to={url} className={`st-button-plain--alt`}>
                            Learn more about {name} <span className="arrow"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutAnalysisToolSection;
